import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const Smallp = styled.p`
	font-size: ${theme.fonts.small};
	font-weight: lighter;
	margin-bottom: ${props => props.marginBottom};
	@media (min-width: 768px) {
		font-size: ${theme.fonts.smallDesktop};
	}
`

const SmallText = ({ children, marginBottom }) => {
	return(
		<Smallp marginBottom={marginBottom}>
			{children}
		</Smallp>
	)
}

export default SmallText