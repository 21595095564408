import React from 'react'
import './index.css'
import Header from './containers/Header'
import Heading1 from './components/Texts/Heading1'
import Heading2 from './components/Texts/Heading2'
import { ReactComponent as Triangle1 } from './images/mobile/triangle.svg'
import About from './containers/AboutMe'
import { ReactComponent as Skills } from './images/mobile/halfCircle.svg'
import WorkWith from './containers/WorkWith'
import Resume from './containers/Resume'
import { ReactComponent as Triangle2 } from './images/mobile/triangle2.svg'
import ColoredSpan from './components/Texts/Span'
import Project from './containers/Project'
import Apply1 from './assets/Apply1.png'
import Apply2 from './assets/Apply2.png'
import Apply3 from './assets/Apply3.png'
import Doodlee1 from './assets/Doodlee1.png'
import Doodlee2 from './assets/Doodlee2.png'
import Doodlee3 from './assets/Doodlee3.png'
import { ReactComponent as Circle } from './images/mobile/footerCircle.svg'
import ContactMe from './containers/ContactMe'
import Footer from './containers/Footer'
import {
  BrowserRouter as Router,
  Switch,
  Route,
	Link,
} from "react-router-dom";
import Contact from './containers/ContactForm'
import ProjectDetail from './containers/ProjectDetail'
import { useMediaQuery } from 'react-responsive'
import styled from 'styled-components'
import Button from './components/Buttons/Button'

const applyImages = [Apply1, Apply2, Apply3]
const doodleeImages = [Doodlee1, Doodlee2, Doodlee3]

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

const ProjectSection = styled.section`
	margin-bottom: 100px;
	@media (min-width: 768px) {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}
`

const App = () => {
  return (
    <div className="App">
			<Router>
				<Header />
				<Switch>
					<Route exact path="/">
						<Heading1 marginBottom={'50px'}>
							Hello! I'm <ColoredSpan>Guillaume</ColoredSpan>, Data Analyst student, JS frontend developer
						</Heading1>
						<Heading2 marginBottom={'200px'} centered margin={'0 70px'}>
							2 years of experience as a frontend developer - Now looking for an apprenticeship as a Data Analyst.
						</Heading2>
						<Triangle1 style={{position: 'absolute', right: '0px', marginTop: '-200px'}} />
						<About />
						<Mobile>
							<Skills style={{position: 'absolute', right: '0px', marginTop: '-100px', marginBottom: '25px' }} />
						</Mobile>
						<WorkWith />
						<Resume />
						<Triangle2 style={{position: 'absolute', left: '0px', marginTop: '-180px'}} />
						<Heading2 centered marginBottom={'70px'}>
							My projects
						</Heading2>
						<ProjectSection>
							<Project isWide projectName={'Apply'} projectTitle={'Organize your job search'} array={applyImages} projectDescription={'I was tasked with front-end development on this tool. React/Redux, Axios, CSS and SCSS. As a group of 5, we used Agile methodology (Scrum). Fully responsive. Deployed with surge. '} link='apply' />
							<Project isWide projectName={'Doodlington'} projectTitle={'Mobile app for art'} array={doodleeImages} projectDescription={'Used to work on the front-end with a friend on this project. It allowed users to create a piece of art (audio, drawing, image...) and to get in instant chatrooms with others. For this, I started learning TypeScript, as well as React Native. '} link='doodlington' />
						</ProjectSection>
						<Circle style={{position: 'absolute', right: '0px', bottom: '250px', zIndex: '-3'}} />
						<ContactMe />
						<Footer />
					</Route>
					<Route exact path='/contact'>
						<Contact />
					</Route>
					<Route exact path='/project/apply'>
						<ProjectDetail projectName={'Apply'} projectTitle={'Organize your job search'} imageArray={applyImages} projectDescription={'Work done in a month. The app allows users to add, edit or delete entries to jobs they’ve applied to. The dashboard fetches and sorts applications according to their current status (to complete, awaiting answer, send follow-up...). The app also offers a to-do list for the user so they could easily track down which applications require attention '} projectLearned={'Working in a Scrum framework (4 sprints, reviews, daily scrum...). Applying my knowledge of React (functionnal components, local state), Redux (global state), SASS (global CSS variables), Axios (for back-end communication), front-end security (using JWT, DOMPurify for user inputs).'} />
					</Route>
					<Route exact path='/project/doodlington'>
						<ProjectDetail projectName={'Doodlington'} projectTitle={'Mobile app for art'} imageArray={doodleeImages} projectDescription={'Started in March 2021, scrapped in 2022. I was tasked with the front-end on this project. This app was made for users who would want to chat with others through art (small drawings, audio recordings...). People got matched in one-on-one rooms where they can rate each other’s creation and talk afterwards. '} projectLearned={'Learned notions in React Native for the mobile app version, TypeScript and GraphQL (to understand the back-end).'} />
					</Route>
					<Route path="*">
					<div style={{position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh'}}>
						<Heading2 marginBottom={'100px'} centered>
							Uh-oh ! It looks like you've reached a dead-end :(
						</Heading2>
						<Circle style={{position: 'absolute', right: '0px', bottom: '0', zIndex: '-3'}} />
						<Link to='/' style={{textDecoration: 'none'}}>
							<Button style={{margin: 'auto'}}>
								To Homepage
							</Button>
						</Link>
					</div>
					</Route>
				</Switch>
			</Router>
		</div>
  );
}

export default App;
