import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const InputText = styled.input.attrs((props) => ({
	type: 'text',
	id: `${props.id}`,
	name: `${props.name}`,
}))`
	outline: none;
	border: none;
	background-color: ${theme.colors.white};
	border-radius: 12px;
	width: 200px;
	height: 30px;
	padding: 0px 10px;
	color: ${theme.colors.font};
	font-size: ${theme.fonts.regular};
	font-family: 'Source Sans Pro', 'Roboto', sans-serif;
	box-shadow: ${theme.shadows.button};
	&:focus {
		border: 1px solid ${theme.colors.button};
	}
	@media (min-width: 768px) {
		width: 300px;
	}
`

const Input = ({ id, name, handleChange }) => {
	return(
			<InputText id={id} name={name} onChange={handleChange} />
	)
}

export default Input