import React from 'react'
import styled from 'styled-components'
import ColoredSpan from '../components/Texts/Span'
import Heading2 from '../components/Texts/Heading2'
import Carousel from '../components/Carousel'
import Regular from '../components/Texts/Regular'
import Button from '../components/Buttons/Button'
import theme from '../Theme/theme'
import { ReactComponent as Circle } from '../images/mobile/footerCircle.svg'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

const ProjectDetailContainer = styled.article`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;
	& .buttonLink{
		margin: 16px;
	}
	.slide {
		margin-bottom: 30px;
	}
	.dot.selected {
		background-color: ${theme.colors.hightlightText};
	}
	& a {
		text-decoration: none;
	}
	@media (min-width: 768px) {
		height: calc(100vh - 145px);
		position: relative;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		align-items: flex-start;
		& .carousel-root {
			flex-basis: 20%;
			align-self: flex-start;
		}
		& h2 {
			flex-basis: 100%;
			text-align: center;
		}
		& .projectContainer {
			flex-direction: column;
			align-self: flex-start;
			flex-basis: 50%;
		}
		& .buttonLink {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-basis: 100%;
			align-self: center;
			
		}
		& .projectContainer {
			align-self: flex-start;
		}
	}
`


const ProjectDetail = ({ projectName, projectTitle, imageArray, projectDescription, projectLearned }) => {
	return(
		<ProjectDetailContainer>
			<Heading2 margin={'0px auto 60px auto'}>
				<ColoredSpan>
					{projectName}
				</ColoredSpan>
				 - {projectTitle}
			</Heading2>
			<Mobile>
				<Carousel array={imageArray} width={'250px'} height={'435px'} />
			</Mobile>
			<Desktop>
				<Carousel array={imageArray} width={'305px'} height={'530px'} />
			</Desktop>
			<div className="projectContainer">
				<Heading2 margin={'16px auto 16px auto'} centered>
					What is it ?
				</Heading2> 
				<Regular margin={'auto 35px'}>
					{projectDescription}
					<br/>
				</Regular>
				<Heading2 margin={'50px auto 16px auto'} centered>
					What I've learned
				</Heading2>
				<Regular margin={'auto 35px auto 35px'}>
					{projectLearned}
				</Regular>
			</div>
			<Link className="buttonLink" to='/'>
				<Button focus>
					Back to navigation
				</Button>
			</Link>
			<Circle style={{position: 'absolute', right: '0px', bottom: '0px', zIndex: '-3'}}/>
		</ProjectDetailContainer>
	)
}

export default ProjectDetail