import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const RegularText = styled.p`
	text-align: ${props => props.centered ? 'center' : 'auto'};
	font-size: ${theme.fonts.regular};
	font-weight: normal;
	margin: ${props => props.margin};
	margin-bottom: ${props => props.marginBottom};
	width: ${props => props.width ? props.width : 'auto'};
	line-height: 1.5rem;
	@media (min-width: 768px) {
		font-size: ${theme.fonts.regularDesktop};
	}
`

const Regular = ({ children, marginBottom, width, margin, centered }) => {
	return(
		<RegularText marginBottom={marginBottom} width={width} margin={margin} centered={centered}>
			{children}
		</RegularText>
	)
}

export default Regular