import React from 'react'
import styled from 'styled-components'
import theme from '../Theme/theme'
import ColoredSpan from '../components/Texts/Span'
import Heading3 from '../components/Texts/Heading3'
import Regular from '../components/Texts/Regular'
import Button from '../components/Buttons/Button'
import CarouselContainer from '../components/Carousel'
import { Link } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}
const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 })
  return isMobile ? children : null
}

const ProjectArticle = styled.article`
	display: flex;
	flex-direction: column;
	margin: 75px 25px;
	& h3 {
		text-align: center;
	}
	& a {
		align-self: center;
	}
	.slide {
		margin-bottom: 30px;
	}
	.control-dots .dot {
		background-color: black;
	}
	.dot.selected {
		background-color: ${theme.colors.hightlightText};
	}
	@media (min-width: 768px) {
		background : rgba(255, 255, 255, 0.4);
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		width: 400px;
		height: 800px;
		padding: 50px 10px;
		border-radius: 20px;
		box-shadow: 0px 2px 30px 0 rgba(0 0 0 / 10%);
		& .control-dots {
			margin-top: 38px;
		}
	}
`

const ProjectDiv = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: baseline;
	margin-bottom: 16px;
	& p {
		margin-left: 16px;
	}
	@media (min-width: 768px) {
		flex-direction: column;
		align-items: center;
		justify-content: center;
		& p {
			margin-top: 16px;
		}
	}
	& .carousel-root {
		align-self: center;
	}
`

const Project = ({ projectName, projectTitle, array, projectDescription, link, address }) => {
	return(
		<ProjectArticle>
			<Heading3 marginBottom={'16px'}>
				<ColoredSpan>{projectName}</ColoredSpan> - {projectTitle}
			</Heading3>
			<Desktop>
				<ProjectDiv>
					<CarouselContainer array={array} width={'200px'} height={'348px'} />
					<Regular>
						{projectDescription}
						{address ?
							<a target="_blank" rel='noreferrer noopener' href={address} style={{textDecoration: 'underline'}}>Check it out here</a>
						:
						''
						}
					</Regular>
				</ProjectDiv>
			</Desktop>
			<Mobile>
				<ProjectDiv>
					<CarouselContainer array={array} width={'200px'} height={'348px'} />
					<Regular>
						{projectDescription}
					</Regular>
				</ProjectDiv>
			</Mobile>
			<Link to={`/project/${link}`} style={{textDecoration: 'none'}}>
				<Button>
					Learn more
				</Button>
			</Link>
		</ProjectArticle>
	)
}

export default Project