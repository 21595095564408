import React from 'react'
import styled from 'styled-components'
import theme from '../Theme/theme'
import { ReactComponent as HeaderTriangleLogo } from '../images/mobile/headerTriangleLogo.svg'
import { Link, useLocation } from 'react-router-dom'
import ButtonEmpty from '../components/Buttons/ButtonEmpty'
import { ReactComponent as Logo} from '../images/mobile/logo.svg'

const HeaderTag = styled.header`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	& a {
		text-decoration: none;
	}
`

const Menu = styled.nav`
	position: absolute;
	top: 16px;
	right: 16px;
	display: flex;
	flex-direction: row;
	font-size: ${theme.fonts.heading3};
	text-decoration: none;
	height: fit-content;
	& a {
		text-decoration: none;
		height: fit-content;
		display: inline-block;
	}
`

const Header = () => {
	const location = useLocation()
	return(
		<HeaderTag>
			<HeaderTriangleLogo />
			<Link style={{tabIndex: '0'}} to="/">
				<Logo style={{position: 'absolute', left: '0px', top: '0px', height: '80px', width: '80px', fill: 'none' }} />
			</Link>
			<Menu>
				{
					location.pathname === '/' 
					? 
					<Link to='/contact'>
						<ButtonEmpty>
							Contact me
						</ButtonEmpty>
					</Link>
					: 
					<Link to='/'>
						<ButtonEmpty>
							To homepage
						</ButtonEmpty>
					</Link>
				}
			</Menu>
		</HeaderTag>
	)
}

export default Header