import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const InputLabel = styled.label.attrs((props) => ({
	htmlFor: props.labelFor,
}))`
	font-size: ${theme.fonts.regular};
	margin-bottom: 5px;
`

const Label = ({ labelFor, children }) => {
	return(
		<InputLabel htmlFor={labelFor}>
			{ children }
		</InputLabel>
	)
}

export default Label