import React from 'react'
import styled from 'styled-components'
import theme from '../Theme/theme'
import SmallText from '../components/Texts/Small'
import { ReactComponent as Logo } from '../images/mobile/logo.svg'


const FooterContainer = styled.footer`
	z-index: -20;
	background-color: ${theme.colors.font};
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: -65px;
	height: 250px;
	width: 100%;
	& p {
		color: ${theme.colors.white};
		margin-top: 16px;
	}
`

const Footer = () => {
	return(
		<FooterContainer>
			<Logo style={{marginTop: '30px'}}/>	
			<SmallText>
				Handcrafted by me &copy; 2023
			</SmallText>
		</FooterContainer>
	)
}

export default Footer