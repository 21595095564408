import React from 'react'
import styled from 'styled-components'
import theme from '../Theme/theme'
import Button from '../components/Buttons/Button'
import Heading3 from '../components/Texts/Heading3'
import { Link } from 'react-router-dom'

const ContactMeDiv = styled.div`
	z-index: 10;
	position: relative;
	margin: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	background-color: ${theme.colors.hightlightText};
	width: 300px;
	height: 130px;
	border-radius: 10px;
	& h3 {
		color: ${theme.colors.white};
		text-align: center;
	}
	@media (min-width: 768px) {
		width: 360px;
		height: 150px;
	}
`

const ContactMe = () => {
	return(
		<ContactMeDiv>
			<Heading3>
				Interested in my profile ?
			</Heading3>
			<Link to="/contact" style={{textDecoration : 'none'}}>
				<Button>
					Contact me
				</Button>
			</Link>
		</ContactMeDiv>
	)
}

export default ContactMe