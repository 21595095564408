import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const Heading = styled.h3`
	font-size: ${theme.fonts.heading3};
	font-weight: bold;
	margin-bottom: ${props => props.marginBottom};
	@media (min-width: 768px) {
		font-size: ${theme.fonts.heading3Desktop};
	}
`

const Heading3 = ({ children, marginBottom }) => {
	return(
		<Heading marginBottom={marginBottom}>
			{children}
		</Heading>
	)
}

export default Heading3