import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'


const EmptyButton = styled.button.attrs(() => ({
	tabIndex: -1,
}))`
	padding: 10px 20px;
	outline: none;
	border: 2px solid ${theme.colors.button};
	box-shadow: ${theme.shadows.button};
	height : auto;
	width : auto;	
	border-radius: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: ${theme.animation.classic};
	background-color: ${theme.colors.white};
	@media (min-width: 768px) {
	}
	&:hover {
		background-color: ${theme.colors.button};
		color: ${theme.colors.white};
	}
	font-size : ${theme.fonts.heading3};
	color: ${theme.colors.button};
`


const emptyButton = ({ children }) => {
	return(
		<EmptyButton>
			{children}
		</EmptyButton>
	)
}

export default emptyButton