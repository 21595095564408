import React from 'react'
import styled from 'styled-components'
import Heading2 from '../components/Texts/Heading2'
import Regular from '../components/Texts/Regular'
import { useMediaQuery } from 'react-responsive'
import { ReactComponent as Circle } from '../images/mobile/skillCircle.svg'


const WorkWithSection = styled.section`
	display: flex;
	flex-direction: column;
	& p {
		margin: 0 16px 200px 16px;
		width: 100px;
	}
	@media (min-width: 768px) {
		align-items: center;
		justify-content: center;
		& p {
			margin: 30px 10%;
			width: auto;
			text-align: center;
		}
	}

`

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 768 })
  return isDesktop ? children : null
}


const WorkWith = () => {
	return(
		<WorkWithSection>
			<Heading2 marginBottom={'16px'} margin={'0 16px'}>
				I work with
			</Heading2>
			<Desktop>
				<Circle style={{marginTop: '16px', marginBottom: '30px'}} />
			</Desktop>
			<Regular>
				As well as knowledge in SQL. Currently learning Python and Power BI.
			</Regular>
		</WorkWithSection>
	)
}

export default WorkWith
