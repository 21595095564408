import React from 'react'
import styled from 'styled-components'
import Regular from '../components/Texts/Regular'
import Button from '../components/Buttons/Button'

const ResumeSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-bottom: 140px;
	& a {
		text-decoration: none;
	}
`

const ResumeRows = styled.div`
	display: flex;
	flex-direction: row;
	gap: 1em;
	align-items: center;
`

const Resume = () => {
	return(
		<ResumeSection>
			<Regular marginBottom={'25px'}>
				Want to know more ?
			</Regular>
			<ResumeRows style={{display: 'flex', flexDirection: 'row'}}>
				<a target="_blank" rel='noreferrer noopener' href={`RESUME_GUILLAUME_BLOCH-BARAUX_EN.pdf`}>
				<Button>
					Download my resume
				</Button>
				</a>
				<a target="_blank" rel='noreferrer noopener' href={`RESUME_GUILLAUME_BLOCH-BARAUX_FR.pdf`}>
				<Button>
					Télécharger mon CV
				</Button>
				</a>
			</ResumeRows>
		</ResumeSection>
	)
}

export default Resume