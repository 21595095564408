import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const Heading = styled.h2`
	font-size: ${theme.fonts.heading2};
	font-weight: bold;
	text-align: ${props => props.centered ? 'center' : 'left'};
	margin: ${props => props.margin};
	margin-bottom: ${props => props.marginBottom};
	@media (min-width: 768px) {
		font-size: ${theme.fonts.heading2Desktop};
	}
`

const Heading2 = ({ children, marginBottom, centered, margin }) => {
	return(
		<Heading margin={margin} marginBottom={marginBottom} centered={centered}>
			{children}
		</Heading>
	)
}

export default Heading2
