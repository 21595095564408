import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const TextAreaContainer = styled.textarea.attrs((props) => ({
	rows: props.rows,
	cols: props.cols,
	name: props.name,
}))`
	outline: none;
	border: none;
	background-color: ${theme.colors.white};
	border-radius: 12px;
	width: 200px;
	height: 130px;
	padding: 0px 10px;
	color: ${theme.colors.font};
	font-size: ${theme.fonts.regular};
	font-family: 'Source Sans Pro', 'Roboto', sans-serif;
	box-shadow: ${theme.shadows.button};
	&:focus {
		border: 1px solid ${theme.colors.button};
	}
	@media (min-width: 768px) {
		width: 650px;
		height: 250px;
	}
`

const TextArea = ({ name, handleChange }) => {
	return(
		<TextAreaContainer name={name} onChange={handleChange} />
	)
}

export default TextArea