import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const StyledButton = styled.button.attrs((props) => ({
	tabIndex: props.focus ? 0 : -1,
}))`
	box-shadow: ${theme.shadows.button};
	border: none;
	height : 50px;
	width : ${theme.sizes.buttons.small};	
	border-radius: 50px;
	background-color: ${theme.colors.button};
	display: flex;
	align-items: center;
	justify-content: center;
	transition: ${theme.animation.classic};
	@media (min-width: 768px) {
		width : ${theme.sizes.buttons.normal};
	}
	&:hover {
		background-color: ${theme.colors.buttonHover};
	}
	font-size : ${theme.fonts.heading3};
	color: ${theme.colors.white};
`

const Button = ({ children, focus }) => {
	return(
		<StyledButton focus={focus}>
			{children}
		</StyledButton>
	)
}

export default Button