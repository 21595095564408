import React, { useState } from 'react'
import styled from 'styled-components'
import Button from '../components/Buttons/Button'
import Label from '../components/Inputs/Label'
import Input from '../components/Inputs/Text'
import TextArea from '../components/Inputs/TextArea'
import Heading2 from '../components/Texts/Heading2'
import emailjs from 'emailjs-com'
import { Link } from 'react-router-dom'
import { ReactComponent as CircleFooter } from '../images/mobile/footerCircle.svg'
import Regular from '../components/Texts/Regular'

const FormContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	min-height: calc(100vh - 145px);
	& button {
		margin: auto;
	}
	& h2 {
		margin-bottom: 50px;
	}
	@media (min-width: 768px) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		& h2 {
			flex-basis: 100%;
		}
	}
`

const InputContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;
`

const SuccessContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 320px;
`
const InputText = styled.div`
	@media (min-width: 768px) {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
`

const ErrorDiv = styled.div`
	display: flex;
	flex-basis: 100%;
	align-items: center;
	justify-content: center;
`

const Contact = () => {
	const [name, setName] = useState('')
	const [mail, setMail] = useState('')
	const [message, setMessage] = useState('')
	const [error, setError] = useState(false)
	const [messageSent, setMessageSent] = useState(false)
	

	const sendEmail = (e) => {
		e.preventDefault();
		console.log(name, mail, message)
		if(!name || !mail || !message) {
			setError(true)
			console.log('invalid')
			return;
		} else {
			emailjs.sendForm('service_weqp414', 'template_8vdtsw2', e.target, 'user_753jpagVW8CRWI2HAj6ow')
			.then(() => {
				setMessageSent(true)
			}, (error) => {
				console.log(error.text);
			});
		}
	}
	
	return(
		<div style={{position: 'relative'}}>
			{ !messageSent ?
				<FormContainer>
				<Heading2 marginBottom={'16px'} centered>
					Thank you for reaching out. <br/> How may I help ?
				</Heading2>
				{
					error
					? 
					<ErrorDiv>

					<Regular marginBottom={'16px'} margin={'0 16px 16px 16px'} centered>
						Couldn't send mail. Have you filled the form ?
					</Regular>
					</ErrorDiv>
					: ''
				}
				<form onSubmit={sendEmail}>
					<InputText>
						<InputContainer>
							<Label labelFor={'name'}>
								Name *
							</Label>
							<Input id={'name'} name={'name'} required handleChange={(e) => setName(e.target.value)} />
						</InputContainer>
						<InputContainer>
							<Label labelFor={'mail'}>
								Mail *
							</Label>
							<Input id={'mail'} name={'mail'} required handleChange={(e) => setMail(e.target.value)} />
						</InputContainer>
					</InputText>
					<InputContainer>
						<Label labelFor={'yourMessage'}>
							Your message *
						</Label>
						<TextArea name={'yourMessage'} required handleChange={(e) => setMessage(e.target.value)} />
					</InputContainer>
					<Button focus>
							Send
					</Button>
				</form>
				<CircleFooter style={{position: 'absolute', right: '0px', bottom: '0px', zIndex: '-3'}} />
			</FormContainer>
			: 
				<div style={{minHeight: '100vh'}}>
					<SuccessContainer>
						<Heading2 marginBottom={'80px'} centered>
							Message sent successfully !
						</Heading2>
						<Link to='/' style={{textDecoration: 'none'}}>
							<Button style={{margin: 'auto'}}>
								To Homepage
							</Button>
						</Link>
					<CircleFooter style={{position: 'absolute', right: '0px', bottom: '0px', zIndex: '-3'}} />
					</SuccessContainer>
				</div>
			}
		</div>		
	)
}

export default Contact