import React from 'react'
import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import './carousel.css'

const CarouselImage = styled.img`
`

const CarouselContainer = ({ width, array }) => {
	return(
		<Carousel autoPlay={false} width={width} interval={3600000} showThumbs={false} swipeable={true} emulateTouch={true} showStatus={false} showArrows={false}>
			{
				array.map((image, index) => {
					return(
						<CarouselImage src={image} key={index} alt="An image showcasing the project" />
					)
				})
			}
		</Carousel>
	)
}

export default CarouselContainer