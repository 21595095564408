const theme = {
	colors : {
		background : '#E0FBFC',
		font : '#293241',
		button : '#5F30E2',
		buttonHover : 'rgba(95, 48, 226, 0.6)',
		buttonEmptyHover : '#AE45EE',
		white: '#FFFFFF',
		hightlightText: '#AE45EE',
	},
	fonts : {
		heading1 : '1.5rem', //bold
		heading2 : '1.25rem', //bold
		heading3 : '1.125rem', //bold
		regular : '1rem', //regular
		small : '0.875rem', //light
		heading1Desktop : '2.5rem',
		heading2Desktop : '2rem',
		heading3Desktop : '1.25rem',
		regularDesktop : '1.25rem',
		smallDesktop : '1.125rem',
	},
	gutters : {
		regular: '16px',
		label : '5px',
	},
	animation : {
		classic : 'all 0.3s ease-out',
	},
	sizes : {
		buttons : {
			small : '200px',
			normal : '250px',
		},
		inputs : {
			small : '30px',
			normal: '60px',
		},
	},
	shadows : {
		button : '0px 2px 7px rgb(0 0 0 / 25%)',
		image : '0px 2px 7px rgb(0 0 0 / 25%)',
	} 
}

export default theme;