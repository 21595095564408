import React from 'react'
import styled from 'styled-components'
import Heading2 from '../components/Texts/Heading2'
import Regular from '../components/Texts/Regular'

const AboutMeSection = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	height: fit-content;
	margin-bottom: 150px;
	@media (min-width: 768px) {
		margin: 0 5% 80px 5%;
	}
`

const About = () => {
	return(
		<AboutMeSection>
			<Heading2 marginBottom={'32px'}>
				About me
			</Heading2>
			<Regular margin={'0 16px'}>
			I’m French, 27 years-old and I’ve had a strong interest in web development for a long time thanks to a friend. After a Master’s degree in finance, I decided to start learning web development. First on my own, and then via a Fullstack JavaScript course. After two years as a frontend developer, I'm now entering a Master in Data Analysis in October 2023, with the idea of specializing in Data Science next year.
			</Regular>
		</AboutMeSection>
	)
}

export default About