import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const Heading = styled.h1`
	font-size: ${theme.fonts.heading1};
	font-weight: bold;
	text-align: center;
	margin: 0 40px;
	margin-bottom: ${props => props.marginBottom};
	@media (min-width : 767px) {
		font-size: ${theme.fonts.heading1Desktop};
	}
`

const Heading1 = ({ children, marginBottom }) => {
	return(
		<Heading marginBottom={marginBottom}>
			{children}
		</Heading>
	)
}

export default Heading1