import React from 'react'
import styled from 'styled-components'
import theme from '../../Theme/theme'

const ColoredSpan = styled.span`
	color: ${theme.colors.hightlightText};
`

const ColoredText = ({ children }) => {
	return(
		<ColoredSpan>
			{children}
		</ColoredSpan>
	)
}

export default ColoredText